import React from "react";
import Translate from "../../../../../modules/Core/Components/Translate";

const ExportDefault = function (props) {
  const { component } = props;

  return (
    <section className="section-white section-top-border" id="startnow">
      <div className="container">
        <div className="row">
          <div className="col-md-12 responsive-top-margins">
            <div className="chat-box">
              <i className="pe-7s-help2"></i>
              <h4>
                <Translate
                  entity="item"
                  eslug="section9"
                  scope="default"
                  slug="title"
                  auto
                />
              </h4>
              <p>
                <Translate
                  entity="item"
                  eslug="section9"
                  scope="default"
                  slug="sub-title"
                  auto
                />
              </p>
              <a href="#contact" className="btn-blue small scrool">
                {component.ucfirst("contact with us")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExportDefault;
