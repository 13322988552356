import React from "react";
import Translate from "../../../../../modules/Core/Components/Translate";
import Image from "../../../../../modules/Core/templates/widget/image";

const ExportDefault = function (props) {
  const { component } = props;
  const account = component.getHelpers("env").getDefaultAccount();
  const repository = component.getHelpers("env").getDefaultRepository();

  return (
    <section
      className="section-white partners-paddings section-top-border section-bottom-border"
      id="connections"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h3 className="section-title">
              <Translate
                entity="item"
                eslug="section7"
                scope="default"
                slug="title"
                auto
              />
            </h3>
          </div>
          <div className="col-md-12 text-center">
            <ul className="partners-mentions">
              <li>
                <Image
                  buildSrc={() =>
                    component
                      .getHelpers("url")
                      .createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "section7",
                        "default",
                        "image1"
                      )
                  }
                  alt="image1"
                />
              </li>
              <li>
                <Image
                  buildSrc={() =>
                    component
                      .getHelpers("url")
                      .createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "section7",
                        "default",
                        "image2"
                      )
                  }
                  alt="image2"
                />
              </li>
              <li>
                <Image
                  buildSrc={() =>
                    component
                      .getHelpers("url")
                      .createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "section7",
                        "default",
                        "image3"
                      )
                  }
                  alt="image3"
                />
              </li>
              <li>
                <Image
                  buildSrc={() =>
                    component
                      .getHelpers("url")
                      .createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "section7",
                        "default",
                        "image4"
                      )
                  }
                  alt="image4"
                />
              </li>
              <li>
                <Image
                  buildSrc={() =>
                    component
                      .getHelpers("url")
                      .createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "section7",
                        "default",
                        "image5"
                      )
                  }
                  alt="image5"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExportDefault;
