import React from "react";
import Translate from "../../../../../modules/Core/Components/Translate";
import Image from "../../../../../modules/Core/templates/widget/image";

const ExportDefault = function (props) {
  const { component } = props;
  const account = component.getHelpers("env").getDefaultAccount();
  const repository = component.getHelpers("env").getDefaultRepository();

  return (
    <section className="section-grey section-top-border">
      <div className="container">
        <div className="row">
          <div
            className="col-md-6 responsive-bottom-margins wow slideInLeft"
            data-wow-delay="0.25s"
            style={{
              visibility: "visible",
              animationDelay: "0.25s",
              animationName: "slideInLeft",
            }}
          >
            <Image
              buildSrc={() =>
                component
                  .getHelpers("url")
                  .createAccountImageUrl(
                    account,
                    repository,
                    "item",
                    "section3",
                    "default",
                    "image"
                  )
              }
              className="width-100 box-shadow"
              alt="pic"
            />
          </div>
          <div className="col-md-6 padding-top-20">
            <div className="features-second">
              <div className="dropcaps-circle">
                <i className="pe-7s-map"></i>
              </div>
              <h4 className="margin-bottom-5">
                <Translate
                  entity="item"
                  eslug="section3"
                  scope="default"
                  slug="heading-1-title"
                  auto
                />
              </h4>
              <p>
                <Translate
                  entity="item"
                  eslug="section3"
                  scope="default"
                  slug="heading-1-content"
                  auto
                />
              </p>
            </div>
            <div className="features-second">
              <div className="dropcaps-circle">
                <i className="pe-7s-rocket"></i>
              </div>
              <h4 className="margin-bottom-5">
                <Translate
                  entity="item"
                  eslug="section3"
                  scope="default"
                  slug="heading-2-title"
                  auto
                />
              </h4>
              <p>
                <Translate
                  entity="item"
                  eslug="section3"
                  scope="default"
                  slug="heading-2-content"
                  auto
                />
              </p>
            </div>
            <div className="features-second">
              <div className="dropcaps-circle">
                <i className="pe-7s-tools"></i>
              </div>
              <h4 className="margin-bottom-5">
                <Translate
                  entity="item"
                  eslug="section3"
                  scope="default"
                  slug="heading-3-title"
                  auto
                />
              </h4>
              <p>
                <Translate
                  entity="item"
                  eslug="section3"
                  scope="default"
                  slug="heading-3-content"
                  auto
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExportDefault;
