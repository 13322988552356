import React from "react";
import Translate from "../../../../../modules/Core/Components/Translate";
import Image from "../../../../../modules/Core/templates/widget/image";

const ExportDefault = function (props) {
  const { component } = props;
  const account = component.getHelpers("env").getDefaultAccount();
  const repository = component.getHelpers("env").getDefaultRepository();

  return (
    <section
      className="section-grey section-top-border section-bottom-border"
      id="routing"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 padding-top-10 padding-left-20">
            <h3>
              <Translate
                entity="item"
                eslug="section5"
                scope="default"
                slug="title"
                auto
              />
            </h3>
            <p className="section-subtitle">
              <Translate
                entity="item"
                eslug="section5"
                scope="default"
                slug="sub-title"
                auto
              />
            </p>
            <p>
              <Translate
                entity="item"
                eslug="section5"
                scope="default"
                slug="content"
                auto
              />
            </p>
            <a href="#startnow" className="btn-blue small scrool">
              {component.ucfirst("start today")}
            </a>
          </div>
          <div
            className="col-md-6 responsive-top-margins wow slideInRight"
            data-wow-delay="0.25s"
            style={{
              visibility: "visible",
              animationDelay: "0.25s",
              animationName: "slideInRight",
            }}
          >
            <div className="video-popup-wrapper">
              <div className="popup-gallery">
                <Image
                  buildSrc={() =>
                    component
                      .getHelpers("url")
                      .createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "section5",
                        "default",
                        "image"
                      )
                  }
                  className="width-100 video-popup-image"
                  alt="pic"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExportDefault;
