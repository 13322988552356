import React from "react";
import Translate from "../../../../../modules/Core/Components/Translate";
import Image from "../../../../../modules/Core/templates/widget/image";

const ExportDefault = function (props) {
  const { component } = props;
  const account = component.getHelpers("env").getDefaultAccount();
  const repository = component.getHelpers("env").getDefaultRepository();

  return (
    <section className="section-white" id="extras">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2 className="section-title">
              <Translate
                entity="item"
                eslug="section6"
                scope="default"
                slug="title"
                auto
              />
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="blog-item">
              <div className="popup-wrapper">
                <div className="popup-gallery">
                  <Image
                    buildSrc={() =>
                      component
                        .getHelpers("url")
                        .createAccountImageUrl(
                          account,
                          repository,
                          "item",
                          "section6",
                          "default",
                          "image-1"
                        )
                    }
                    className="width-100"
                    alt="pic"
                  />
                </div>
              </div>
              <div className="blog-item-inner">
                <h3 className="blog-title">
                  <Translate
                    entity="item"
                    eslug="section6"
                    scope="default"
                    slug="box-1-title"
                    auto
                  />
                </h3>
                <p>
                  <Translate
                    entity="item"
                    eslug="section6"
                    scope="default"
                    slug="box-1-content"
                    auto
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="blog-item">
              <div className="popup-wrapper">
                <div className="popup-gallery">
                  <Image
                    buildSrc={() =>
                      component
                        .getHelpers("url")
                        .createAccountImageUrl(
                          account,
                          repository,
                          "item",
                          "section6",
                          "default",
                          "image-2"
                        )
                    }
                    className="width-100"
                    alt="pic"
                  />
                </div>
              </div>
              <div className="blog-item-inner">
                <h3 className="blog-title">
                  <Translate
                    entity="item"
                    eslug="section6"
                    scope="default"
                    slug="box-2-title"
                    auto
                  />
                </h3>
                <p>
                  <Translate
                    entity="item"
                    eslug="section6"
                    scope="default"
                    slug="box-2-content"
                    auto
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="blog-item">
              <div className="popup-wrapper">
                <div className="popup-gallery">
                  <Image
                    buildSrc={() =>
                      component
                        .getHelpers("url")
                        .createAccountImageUrl(
                          account,
                          repository,
                          "item",
                          "section6",
                          "default",
                          "image-3"
                        )
                    }
                    className="width-100"
                    alt="pic"
                  />
                </div>
              </div>
              <div className="blog-item-inner">
                <h3 className="blog-title">
                  <Translate
                    entity="item"
                    eslug="section6"
                    scope="default"
                    slug="box-3-title"
                    auto
                  />
                </h3>
                <p>
                  <Translate
                    entity="item"
                    eslug="section6"
                    scope="default"
                    slug="box-3-content"
                    auto
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExportDefault;
