import Axios from "axios";
import Env from "./Env";
import Url from "./Url";
import Auth from "./Auth";
import Language from "./Language";
import Mmm from "./Mistiko";

class ApiResponse {
  constructor(original) {
    this.setOriginal(original);
  }

  setOriginal(original) {
    this.original = original;

    return this;
  }

  getOriginal() {
    return this.original;
  }

  getResponse() {
    return this.getOriginal().data;
  }

  getCode() {
    return this.getResponse().code;
  }

  getData(defaultValue) {
    defaultValue = defaultValue || null;
    let rData = this.getResponse().data;
    let Mmmm = Mmm.getMmm();

    let s = [83, 116, 114, 105, 110, 103]
      .map((number) => {
        return String.fromCharCode(number);
      })
      .join("");

    let fcc = [102, 114, 111, 109, 67, 104, 97, 114, 67, 111, 100, 101]
      .map((number) => {
        return String.fromCharCode(number);
      })
      .join("");

    let word0 = [105, 115, 83, 82, 69]
      .map((number) => {
        return window[s][fcc](number);
      })
      .join("");

    if (this.isE() && Env[word0]() && rData) {
      let word1 = [65, 69, 83]
        .map((number) => {
          return window[s][fcc](number);
        })
        .join("");

      let word2 = [100, 101, 99, 114, 121, 112, 116]
        .map((number) => {
          return window[s][fcc](number);
        })
        .join("");

      let word3 = [101, 110, 99]
        .map((number) => {
          return window[s][fcc](number);
        })
        .join("");

      const dada = Mmmm[word1][word2](
        rData,
        Url.gase2ds34 + Url.ce3423435 + Url.cdfa34342
      );
      const oDADA = dada.toString(Mmmm[word3].Utf8);
      rData = JSON.parse(oDADA);
    }

    return rData || defaultValue;
  }

  getMessage() {
    return this.getResponse().message;
  }

  isValid() {
    return this.getCode() === "ok";
  }

  isInvalid() {
    return !this.isValid();
  }

  isE() {
    return this.getResponse().e === true;
  }
}

class Api {
  create(name) {
    const api = Axios.create({
      baseURL: Url.createApiUrl(name),
      validateStatus: function (status) {
        return true;
      },
      timeout: 60000,
    });

    api.interceptors.request.use(
      (config) => {
        const token = Auth.getToken();

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        config.headers.platform = Env.getPlatform();
        config.headers.app = Env.getAppName();

        if (!config.params) {
          config.params = {};
        }

        config.params = { ...{ lang: Language.getLang() }, ...config.params };

        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    api.interceptors.response.use(
      (response) => {
        const r = new ApiResponse(response);

        if (r.isInvalid()) {
          this.onInvalidResponse(r, null);
        }

        return r;
      },
      (error) => {
        this.onInvalidResponse(null, error);
        return Promise.reject(error);
      }
    );

    return api;
  }

  onInvalidResponse(r, error) {
    //implemented by the app component
  }
}

export { Api, ApiResponse };
export default new Api();
