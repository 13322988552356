import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Pages from "../Components/Pages";

const ExportDefault = (props) => {
  const { component } = props;

  return (
    component.isLoaded() && (
      <Router>
        <Switch>
          <Route exact path="/" component={Pages.Home} />
          <Route
            exact
            path="/d41d8cd98f00b204e9800998ecf8427e"
            component={Pages.Tax}
          />
        </Switch>
      </Router>
    )
  );
};

export default ExportDefault;
