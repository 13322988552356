import React from "react";
import Translate from "../../../../../modules/Core/Components/Translate";
import Image from "../../../../../modules/Core/templates/widget/image";

const ExportDefault = function (props) {
  const { component } = props;
  const account = component.getHelpers("env").getDefaultAccount();
  const repository = component.getHelpers("env").getDefaultRepository();

  return (
    <section className="home-section" id="home">
      <div className="container">
        <div className="row">
          <div className="col-md-6 padding-top-40">
            <h2>All-in-One Platform</h2>
            <p className="font-size-10">
              <Translate
                entity="item"
                eslug="section1"
                scope="default"
                slug="sub-title"
                auto
              />
            </p>
            <ul className="home-benefits">
              <Translate
                entity="item"
                eslug="section1"
                scope="aims"
                auto
                renderer={(props) => {
                  const translations = props.component.getTranslation([]);

                  return translations.map((translation, index) => {
                    return (
                      <li key={`section-1-aim-${index}`}>
                        <i className="pe-7s-check"></i>
                        {translation.html ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translation.translation,
                            }}
                          ></span>
                        ) : (
                          translation.translation
                        )}
                      </li>
                    );
                  });
                }}
              />
            </ul>
          </div>
          <div className="col-md-6">
            <Image
              buildSrc={() =>
                component
                  .getHelpers("url")
                  .createAccountImageUrl(
                    account,
                    repository,
                    "item",
                    "section1",
                    "default",
                    "image"
                  )
              }
              className="hero-image width-100"
              alt="pic"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExportDefault;
