import React from "react";
import Translate from "../../../../../modules/Core/Components/Translate";

const ExportDefault = function (props) {
  return (
    <section className="section-blue medium-paddings" id="team">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center padding-top-10 padding-bottom-20">
            <h3 className="section-title white-text">
              <Translate
                entity="item"
                eslug="section10"
                scope="default"
                slug="title"
                auto
              />
            </h3>
            <p className="section-subtitle white">
              <Translate
                entity="item"
                eslug="section10"
                scope="default"
                slug="sub-title"
                auto
              />
            </p>
          </div>
          <div className="col-md-12">
            <p className="white-text">
              <Translate
                entity="item"
                eslug="section10"
                scope="default"
                slug="content"
                auto
              />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExportDefault;
