import React from "react";

const ExportDefault = function (props) {
  const { component } = props;
  const iframe = component.getHelpers("value").getValue("location-map-iframe");

  return (
    <section className="section-grey section-top-border">
      <div className="container">
        <div className="row">
          <div className="col-md-12 responsive-bottom-margins">
            <h4>{component.ucfirst("our location")}</h4>
            <div dangerouslySetInnerHTML={{ __html: iframe }}></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExportDefault;
