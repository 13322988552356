import React from "react";

const ExportDefault = function (props) {
  const { component } = props;

  return (
    <header className="header">
      <nav className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand" href="/">
              Automatapp
            </a>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <i className="fas fa-bars"></i>
              </span>
            </button>
            <div className="navbar-collapse collapse" id="navbarCollapse">
              <ul className="navbar-nav ml-auto">
                <li>
                  <a href="#aim">{component.ucfirst("aim")}</a>
                </li>
                <li>
                  <a href="#ecommerce">{component.ucfirst("ecommerce")}</a>
                </li>
                <li>
                  <a href="#routing">{component.ucfirst("routing")}</a>
                </li>
                <li>
                  <a href="#extras">{component.ucfirst("extras")}</a>
                </li>
                <li>
                  <a href="#faq">{component.ucfirst("faq")}</a>
                </li>
                <li>
                  <a href="#team">{component.ucfirst("team")}</a>
                </li>
                <li>
                  <a href="#contact">{component.ucfirst("contact")}</a>
                </li>
                <li className="discover-link">
                  <a href="#startnow" className="external discover-btn">
                    {component.ucfirst("start today")}
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </nav>
    </header>
  );
};

export default ExportDefault;
