import React from "react";
import Header from "./home/header";
import Section1 from "./home/section1";
import Section2 from "./home/section2";
import Section3 from "./home/section3";
import Section4 from "./home/section4";
import Section5 from "./home/section5";
import Section6 from "./home/section6";
import Section7 from "./home/section7";
import Section8 from "./home/section8";
import Section9 from "./home/section9";
import Section10 from "./home/section10";
import Section11 from "./home/section11";
import Footer from "./home/footer";

const ExportDefault = function (props) {
  return (
    <React.Fragment>
      <Header {...props} />
      <Section1 {...props} />
      <Section2 {...props} />
      <Section3 {...props} />
      <Section4 {...props} />
      <Section5 {...props} />
      <Section6 {...props} />
      <Section7 {...props} />
      <Section8 {...props} />
      <Section9 {...props} />
      <Section10 {...props} />
      <Section11 {...props} />
      <Footer {...props} />
    </React.Fragment>
  );
};

export default ExportDefault;
