import React from "react";
import File from "../../../../../modules/Core/Components/File";
import Image from "../../../../../modules/Core/templates/widget/image";

const ExportDefault = function (props) {
  const { component } = props;
  const urlHelper = component.getHelpers("url");
  const account = component.getHelpers("env").getDefaultAccount();
  const repository = component.getHelpers("env").getDefaultRepository();

  return (
    <section className="tax-section" id="home">
      <div className="container">
        <div className="row">
          <File
            entity="item"
            eslug="tax"
            renderer={(props) => {
              const { component } = props;

              const files = component.getData("default.files", []);

              if (!files.length) {
                return (
                  <div className="col-md-12">
                    <div className="tax-container">
                      {component.ucfirst("no files available")}
                    </div>
                  </div>
                );
              }

              return files.map((file, index) => {
                const { name, entity, entity_slug, scope, slug } = file;

                return (
                  <div key={index} className="col-md-2 text-center">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={urlHelper.createAccountFileUrl(
                        account,
                        repository,
                        entity,
                        entity_slug,
                        scope,
                        slug
                      )}
                    >
                      <Image
                        buildSrc={() =>
                          urlHelper.createAccountFileUrl(
                            account,
                            repository,
                            "vault",
                            "files",
                            "default",
                            "pdf-image"
                          )
                        }
                        className="hero-image width-100"
                        alt="pic"
                      />
                    </a>
                    <span>{name}</span>
                  </div>
                );
              });
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default ExportDefault;
