import React from "react";
import Translate from "../../../../../modules/Core/Components/Translate";
import Image from "../../../../../modules/Core/templates/widget/image";

const ExportDefault = function (props) {
  const { component } = props;
  const account = component.getHelpers("env").getDefaultAccount();
  const repository = component.getHelpers("env").getDefaultRepository();

  return (
    <section className="section-blue section-top-border" id="faq">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 text-center padding-bottom-40">
            <h2 className="section-title white-text">
              <Translate
                entity="item"
                eslug="section8"
                scope="default"
                slug="title"
                auto
              />
            </h2>
            <p className="section-subtitle white">
              <Translate
                entity="item"
                eslug="section8"
                scope="default"
                slug="sub-title"
                auto
              />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 padding-top-10">
            <div
              id="carouselIndicators"
              className="carousel slide carousel-showcase"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="row">
                    <Translate
                      id="slider"
                      entity="item"
                      eslug="section8"
                      scope="slider"
                      renderer={(props) => {
                        const translations = props.component.getTranslation([]);

                        return translations.map((translation, index) => {
                          index = index + 1;

                          const image = component
                            .getHelpers("url")
                            .createAccountImageUrl(
                              account,
                              repository,
                              "item",
                              "section8",
                              "default",
                              `image${index}`
                            );

                          return (
                            <div
                              key={`section-8-slide-${index}`}
                              className="col-md-4 col-sm-4 col-xs-6"
                            >
                              <figure className="gallery-item">
                                <div className="popup-gallery popup-gallery-rounded portfolio-pic">
                                  <a className={`popup`} href={image}>
                                    <Image
                                      buildSrc={() => image}
                                      className="width-100"
                                      alt="image1"
                                    />
                                    <span className="eye-wrapper">
                                      <i
                                        className="fa fa-search-plus eye-icon"
                                        style={{ fontSize: "38px" }}
                                      ></i>
                                    </span>
                                  </a>
                                </div>
                                <div className="portfolio-box">
                                  <h3>
                                    <a href="/">{translation.translation}</a>
                                  </h3>
                                  <p className="portfolio-info">
                                    <Translate
                                      entity="item"
                                      eslug="section8"
                                      scope="default"
                                      slug={`box-${index}-content`}
                                      auto
                                    />
                                  </p>
                                </div>
                              </figure>
                            </div>
                          );
                        });
                      }}
                      onUpdate={(component) => {
                        if (window.$) {
                          const popups = window
                            .$(".popup-gallery")
                            .find("a.popup");

                          if (popups.length) {
                            popups.magnificPopup({
                              type: "image",
                              gallery: {
                                enabled: true,
                              },
                            });
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExportDefault;
