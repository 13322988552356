import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/home";

export default class Home extends Page {
  title = "home";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.addScripts(
      {
        "jquery-3.3.1.min": "/js/jquery-3.3.1.min",
        "bootstrap.min.js": "/js/bootstrap.min",
        "jquery.scrollTo-min": "/js/jquery.scrollTo-min",
        "jquery.magnific-popup.min": "/js/jquery.magnific-popup.min",
        "jquery.nav": "/js/jquery.nav",
        wow: "/js/wow",
        plugins: "/js/plugins",
        custom: "/js/custom",
      },
      true,
      false,
      (name) => {
        if (name === "jquery.magnific-popup.min") {
          this.getComponents()
            .findById("slider")
            .forEach((component) => {
              component.scope();
            });
        }
      }
    );
  }
}
