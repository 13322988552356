import Env from "./Env";

class Url {
  gase2ds34 = "4K7cWUsMm/5UjXN";
  ce3423435 = "DVZ6Nxr3z+PfD8cpC";
  cdfa34342 = "dsH6e3mIxpA=";

  encode(url) {
    return encodeURIComponent(url);
  }

  decode(url) {
    return decodeURIComponent(url);
  }

  encodePath() {
    return this.encode(window.location.pathname);
  }

  redirect(url) {
    window.location.href = url;
    return this;
  }

  isExternalUrl(url) {
    return url.indexOf("http") === 0;
  }

  createApiUrl(name) {
    return `${Env.get("api_url")}/${name}/v${Env.get("api_version")}`;
  }

  createAccountImageUrl(account, repository, entity, eslug, scope, slug) {
    scope = scope || "default";

    if (Env.getPlatformVersion() === 1) {
      return (
        this.createApiUrl("share") +
        `/file/account/${account}/app/${repository}/entity/${entity}/eslug/${eslug}/scope/${scope}${
          slug ? `/slug/${slug}` : ""
        }`
      );
    } else {
      return (
        this.createApiUrl("share") +
        `/content/account/${account}/repository/${repository}/file/entity/${entity}/eslug/${eslug}/scope/${scope}${
          slug ? `/slug/${slug}` : ""
        }`
      );
    }
  }

  createAccountFileUrl(account, repository, entity, eslug, scope, slug) {
    scope = scope || "default";

    if (Env.getPlatformVersion() === 1) {
      return (
        this.createApiUrl("share") +
        `/file/account/${account}/app/${repository}/entity/${entity}/eslug/${eslug}/scope/${scope}${
          slug ? `/slug/${slug}` : ""
        }`
      );
    } else {
      return (
        this.createApiUrl("share") +
        `/content/account/${account}/repository/${repository}/file/entity/${entity}/eslug/${eslug}/scope/${scope}${
          slug ? `/slug/${slug}` : ""
        }`
      );
    }
  }
}

export { Url };
export default new Url();
