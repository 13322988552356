import React from "react";
import Translate from "../../../../../modules/Core/Components/Translate";

const ExportDefault = function (props) {
  const { component } = props;

  const year = component.getHelpers("date-time").getCurrentYear();
  const lang = component.getHelpers("language").getLang();
  const nextLang = lang === "en" ? "el" : "en";

  const changeLanguage = () => {
    component.getHelpers("literal").changeLanguage(nextLang);
  };

  return (
    <div className="footer">
      <div className="container-fluid px-0" id="contact">
        <div className="row no-gutters mx-0">
          <div className="col-md-4 footer-white-box text-center">
            <Translate
              entity="item"
              eslug="footer"
              scope="default"
              slug="box1"
              auto
            />
          </div>
          <div className="col-md-4 footer-blue-box text-center">
            <Translate
              entity="item"
              eslug="footer"
              scope="default"
              slug="box2"
              auto
            />
          </div>
          <div className="col-md-4 footer-grey-box text-center">
            <Translate
              entity="item"
              eslug="footer"
              scope="default"
              slug="box3"
              auto
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 footer-bottom text-center">
            <p>
              Copyright © {year} <strong>Automatapp</strong>
            </p>
            <p>
              <a href="/" className="pointer" onClick={changeLanguage}>
                <img
                  style={{ maxHeight: "15px" }}
                  src={`images/${nextLang}.png`}
                  alt={nextLang}
                />
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportDefault;
