import React from "react";
import Translate from "../../../../../modules/Core/Components/Translate";

const ExportDefault = function (props) {
  return (
    <section
      className="section-white section-top-border section-bottom-border"
      id="ecommerce"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center padding-bottom-10">
            <h3 className="section-title">
              <Translate
                entity="item"
                eslug="section4"
                scope="default"
                slug="title"
                auto
              />
            </h3>
            <p className="section-subtitle">
              <Translate
                entity="item"
                eslug="section4"
                scope="default"
                slug="sub-title"
                auto
              />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div
              className="feature-box wow fadeIn"
              data-wow-delay="0.25s"
              style={{
                visibility: "visible",
                animationDelay: "0.25s",
                animationName: "fadeIn",
              }}
            >
              <i className="pe-7s-display1"></i>
              <h4>
                <Translate
                  entity="item"
                  eslug="section4"
                  scope="default"
                  slug="box-1-title"
                  auto
                />
              </h4>
              <p>
                <Translate
                  entity="item"
                  eslug="section4"
                  scope="default"
                  slug="box-1-content"
                  auto
                />
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="feature-box wow fadeIn"
              data-wow-delay="0.5s"
              style={{
                visibility: "visible",
                animationDelay: "0.5s",
                animationName: "fadeIn",
              }}
            >
              <i className="pe-7s-shopbag"></i>
              <h4>
                <Translate
                  entity="item"
                  eslug="section4"
                  scope="default"
                  slug="box-2-title"
                  auto
                />
              </h4>
              <p>
                <Translate
                  entity="item"
                  eslug="section4"
                  scope="default"
                  slug="box-2-content"
                  auto
                />
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="feature-box wow fadeIn"
              data-wow-delay="0.75s"
              style={{
                visibility: "visible",
                animationDelay: "0.75s",
                animationName: "fadeIn",
              }}
            >
              <i className="pe-7s-shield"></i>
              <h4>
                <Translate
                  entity="item"
                  eslug="section4"
                  scope="default"
                  slug="box-3-title"
                  auto
                />
              </h4>
              <p>
                <Translate
                  entity="item"
                  eslug="section4"
                  scope="default"
                  slug="box-3-content"
                  auto
                />
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div
              className="feature-box wow fadeIn"
              data-wow-delay="1s"
              style={{
                visibility: "visible",
                animationDelay: "1s",
                animationName: "fadeIn",
              }}
            >
              <i className="pe-7s-cloud"></i>
              <h4>
                <Translate
                  entity="item"
                  eslug="section4"
                  scope="default"
                  slug="box-4-title"
                  auto
                />
              </h4>
              <p>
                <Translate
                  entity="item"
                  eslug="section4"
                  scope="default"
                  slug="box-4-content"
                  auto
                />
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="feature-box wow fadeIn"
              data-wow-delay="1.25s"
              style={{
                visibility: "visible",
                animationDelay: "1.25s",
                animationName: "fadeIn",
              }}
            >
              <i className="pe-7s-print"></i>
              <h4>
                <Translate
                  entity="item"
                  eslug="section4"
                  scope="default"
                  slug="box-5-title"
                  auto
                />
              </h4>
              <p>
                <Translate
                  entity="item"
                  eslug="section4"
                  scope="default"
                  slug="box-5-content"
                  auto
                />
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="feature-box wow fadeIn"
              data-wow-delay="1.5s"
              style={{
                visibility: "visible",
                animationDelay: "1.5s",
                animationName: "fadeIn",
              }}
            >
              <i className="pe-7s-home"></i>
              <h4>
                <Translate
                  entity="item"
                  eslug="section4"
                  scope="default"
                  slug="box-6-title"
                  auto
                />
              </h4>
              <p>
                <Translate
                  entity="item"
                  eslug="section4"
                  scope="default"
                  slug="box-6-content"
                  auto
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExportDefault;
