import React from "react";
import Translate from "../../../../../modules/Core/Components/Translate";

const ExportDefault = function (props) {
  return (
    <section className="section-white section-top-border" id="aim">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2 className="section-title">
              <Translate
                entity="item"
                eslug="section2"
                scope="default"
                slug="title"
                auto
              />
            </h2>
            <p className="section-subtitle">
              <Translate
                entity="item"
                eslug="section2"
                scope="default"
                slug="sub-title"
                auto
              />
            </p>
          </div>
          <div className="col-md-6">
            <div className="main-services">
              <i className="pe-7s-clock"></i>
              <div className="main-services-text">
                <h3>
                  <Translate
                    entity="item"
                    eslug="section2"
                    scope="default"
                    slug="box-1-title"
                    auto
                  />
                </h3>
                <p>
                  <Translate
                    entity="item"
                    eslug="section2"
                    scope="default"
                    slug="box-1-content"
                    auto
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="main-services featured-box">
              <i className="pe-7s-piggy"></i>
              <div className="main-services-text">
                <h3>
                  <Translate
                    entity="item"
                    eslug="section2"
                    scope="default"
                    slug="box-2-title"
                    auto
                  />
                </h3>
                <p>
                  <Translate
                    entity="item"
                    eslug="section2"
                    scope="default"
                    slug="box-2-content"
                    auto
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="main-services">
              <i className="pe-7s-graph1"></i>
              <div className="main-services-text">
                <h3>
                  <Translate
                    entity="item"
                    eslug="section2"
                    scope="default"
                    slug="box-3-title"
                    auto
                  />
                </h3>
                <p>
                  <Translate
                    entity="item"
                    eslug="section2"
                    scope="default"
                    slug="box-3-content"
                    auto
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="main-services">
              <i className="pe-7s-config"></i>
              <div className="main-services-text">
                <h3>
                  <Translate
                    entity="item"
                    eslug="section2"
                    scope="default"
                    slug="box-4-title"
                    auto
                  />
                </h3>
                <p>
                  <Translate
                    entity="item"
                    eslug="section2"
                    scope="default"
                    slug="box-4-content"
                    auto
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExportDefault;
