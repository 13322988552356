import React from "react";
import Header from "./home/header";
import Files from "./tax/files";
import Footer from "./home/footer";

const ExportDefault = function (props) {
  return (
    <React.Fragment>
      <Header {...props} />
      <Files {...props} />
      <Footer {...props} />
    </React.Fragment>
  );
};

export default ExportDefault;
