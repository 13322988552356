import State from './State';
import Value from './Value';

class Language
{
    setLang(lang) {
        State.set('lang',lang,{path:'/'});
        return this;
    }

    getLang() {
        const defaultLang = Value.getValue('lang','el');
        return State.get('lang',defaultLang);
    }
}

export { Language };
export default new Language();